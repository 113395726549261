@tailwind base;
@tailwind components;
@tailwind utilities;

/* font-face */
@font-face {
  font-family: "Pretendard Variable";
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/Pretendard-Regular.otf");
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/Pretendard-Medium.otf");
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 600;
  font-style: normal;
  src: url("./assets/fonts/Pretendard-SemiBold.otf");
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/Pretendard-Bold.otf");
}

/* swiper */
.swiper {
  position: relative;
  height: 100%;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: center;
}
.swiper-pagination {
  position: absolute;
  bottom: 8px;
  right: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 4px 9px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #fff;
  z-index: 100;
}

/* datepicker */
.react-datepicker {
  width: 312px;
  z-index: 10 !important;
  border-radius: 24px !important;
  overflow: hidden !important;
  font-family: "Pretendard Variable", sans-serif !important;
}
.react-datepicker-popper {
  z-index: 10 !important;
}
.datepickerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  background-color: #fff;
}
.react-datepicker__header {
  background-color: #fff !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 26px !important;
  letter-spacing: -0.3px !important;
  padding: 18px 0 !important;
}
.react-datepicker__navigation {
  top: 13px !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  background-color: #fff !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 26px !important;
  letter-spacing: -0.3px !important;
  margin-bottom: 26px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  top: 13px !important;
}
.react-datepicker__input-container,
.react-datepicker-ignore-onclickoutside,
.react-datepicker__input-container input {
  height: 48px;
  background-color: #F2F4F5;
  text-align: center;
}
.react-datepicker-ignore-onclickoutside {
  width: 100%;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}
.react-datepicker__input-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
}
.react-datepicker__month {
  margin: 16px !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  width: 11% !important;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  letter-spacing: -0.3px !important;
  width: 31% !important;
  padding: 8px 0 !important;
}

.react-datepicker__day-name {
  width: 11% !important;
}
.react-datepicker__day,
.react-datepicker__time-name {
  width: 12% !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  padding: 7px 0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  font-weight: 600 !important;
  letter-spacing: -0.15px !important;
  background-color: #FDEAEB !important;
  color: #741538 !important;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__day:hover,
.react-datepicker__year-text:hover {
  background-color: #FFF5F5 !important;
}